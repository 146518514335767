import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class CommonFuncsService {
    env = environment;
    private texts = [];

    constructor(http: HttpClient) {
    }

    setAlphabet(texts) {
        this.texts = texts;
    }

    getText(subarea): any {
        if (this.texts.length > 0) {
            return this.texts.find(x => x.subarea === subarea);
        }
        return null;
    }
}
