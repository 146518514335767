import {Component, OnInit, Input} from '@angular/core';
import {NewProductSlider} from '../../../data/slider';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {ProductsService} from '../../../../services/products.service';

@Component({
    selector: 'app-product-box-vertical-slider',
    templateUrl: './product-box-vertical-slider.component.html',
    styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

    @Input() title = 'New Product'; // Default
    @Input() type = 'fashion'; // Default Fashion

    public products: Product[] = [];

    public NewProductSliderConfig: any = NewProductSlider;

    constructor(private productService: ProductsService) {
        const url = `latest`;
        this.productService.listSubArea(1, 9, {}, url).then(r => this.products = r.items);
    }

    ngOnInit(): void {
    }

    getProductLink(prod: Product) {
        return `/products/${prod.id}/${encodeURIComponent(prod.name)}`;
    }
}
