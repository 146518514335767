import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import {NewsService} from "../../../services/news.service";
import {EventService} from "../../../services/events.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-last-news-events',
  templateUrl: './last-news-events.component.html',
  styleUrls: ['./last-news-events.component.scss']
})
export class LastNewsEventsComponent implements OnInit {
  last: any[] = [];
  events: any[] = [];
  events$ = new BehaviorSubject(false);
  items$ = new BehaviorSubject(false);

  constructor(
      private service: NewsService,
      private serviceEve: EventService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadLastNews();
    this.loadLastEvents();
  }

  loadLastNews() {
    this.service.listSubArea(1, 5, {}, 'website').then(r => {
      this.last = r.items;
      this.items$.next(true);
    });
  }

  loadLastEvents() {
    this.serviceEve.listSubArea(1, 3, {}, 'website').then(r => {
      this.events = r.items;
      this.events$.next(true);
    });
  }

  goToNews(l): void {
    this.router.navigate([`/news/${l.id}/${encodeURIComponent(l.title.pt)}`]);
  }

  goToEvent(e): void {
    this.router.navigate([`/events/${e.id}/${encodeURIComponent(e.title.pt)}`]);
  }
}
