import {Injectable, HostListener} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

// Menu
export interface Menu {
    path?: string;
    title?: string;
    type?: string;
    megaMenu?: boolean;
    image?: string;
    active?: boolean;
    badge?: boolean;
    badgeText?: string;
    extClass?: string;
    children?: Menu[];
}

@Injectable({
    providedIn: 'root'
})

export class NavService {

    constructor() {
    }

    public screenWidth: any;
    public leftMenuToggle: boolean = false;
    public mainMenuToggle: boolean = false;

    // Windows width
    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.screenWidth = window.innerWidth;
    }

    MENUITEMS: Menu[] = [
        {
            title: 'Compra Nosso', type: 'sub', active: false, children: [
                // {
                // 	title: 'clothing', type: 'sub', active: false, children: [
                // 		{ path: '/home/fashion', title: 'fashion-01', type: 'link' },
                // 		{ path: '/home/fashion-2', title: 'fashion-02', type: 'link' },
                // 		{ path: '/home/fashion-3', title: 'fashion-03', type: 'link' }
                // 	]
                // },
                {path: '/pages/aboutus', title: 'Sobre nós', type: 'link'},
                {title: 'Apoio', type: 'link', path: '/pages/services' },
                // {path: '/pages/courses', title: 'Capacitação', type: 'link'},
                // {path: '/events', title: 'Eventos', type: 'link'},
                {path: '/pages/contacts', title: 'Contactos', type: 'link'},
                // {path: '/activities', title: 'Actividades', type: 'link'},
                // { path: '/home/beauty', title: 'beauty', type: 'link' },
                // { path: '/home/electronics', title: 'electronics', type: 'link' },
                // { path: '/home/pets', title: 'pets', type: 'link' },
                // { path: '/home/gym', title: 'gym', type: 'link' },
                // { path: '/home/tools', title: 'tools', type: 'link' },
                // { path: '/home/shoes', title: 'shoes', type: 'link' },
                // { path: '/home/bags', title: 'bags', type: 'link' },
                // { path: '/home/marijuana', title: 'marijuana', type: 'link' }
            ]
        },
        {
            title: 'Montra', type: 'link', active: false, path: '/sellers'
        },
        {
            title: 'Capacitação', type: 'link', active: false, path: '/pages/courses'
        },
        {
            title: 'Actividades', type: 'link', active: false, path: '/activities'
        },
        {
            title: 'Feed', type: 'sub', active: false, children: [
                {path: '/news', title: 'Notícias', type: 'link'},
                {title: 'Publicações', type: 'link', path: '/blog' },
            ]
        },
        {
            title: 'Eventos', type: 'link', active: false, path: '/events'
        },
        // {
        //     title: 'Participações', type: 'link', active: false, path: '/participations'
        // },
        // {
        //     title: 'Publicações', type: 'link', active: false, path: '/blog'
        // },

        // {
        //     title: 'Apoio', type: 'link', active: false, path: '/pages/services'
        // },
        {
            title: 'Emprego', type: 'extLink', active: false, path: 'https://www.emprego.co.mz/'
        },
        // {
        //     title: 'Concursos', type: 'extLink', active: false, path: 'http://poupamais.co.mz/'
        // },
        {
            title: 'Quiz', type: 'extLink', active: false, path: 'http://poupamais.co.mz/', extClass: 'pdr-0'
        }
    ];

    LEFTMENUITEMS: Menu[] = [
        {
            title: 'compra nosso', type: 'sub', megaMenu: true, active: false, children: [
                {
                    title: 'Sobre nós', type: 'link', active: false,
                },
                {
                    title: 'Capacitação', type: 'link', active: false,
                },
                {
                    title: 'Eventos', type: 'link', active: false
                },
                {
                    title: 'Contactos', type: 'link', active: false
                },
            ]
        },
        {
            title: 'Notícias', type: 'link', active: false, path: '/news'
        },
        {
            title: 'Actividades', type: 'link', active: false, path: '/activities'
        },
        {
            title: 'Montra', type: 'link', active: false, path: '/sellers'
        },
        {
            title: 'Apoio', type: 'link', active: false, path: '/pages/services'
        },
        {
            path: 'https://www.emprego.co.mz/', title: 'Emprego', type: 'link'
        },
        // {
        //     path: 'https://poupamais.co.mz/', title: 'Concursos', type: 'link'
        // }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
    leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
