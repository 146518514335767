import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ShopComponent} from './shop/shop.component';
import {PagesComponent} from './pages/pages.component';
import {ElementsComponent} from './elements/elements.component';
import {ParticipationsListComponent} from "./participations/participations-list/participations-list.component";

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home/fashion',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'shop',
        component: ShopComponent,
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
    },
    {
        path: 'pages',
        component: PagesComponent,
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'news',
        component: PagesComponent,
        loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
    },
    {
        path: 'blog',
        component: PagesComponent,
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
    },
    {
        path: 'events',
        component: PagesComponent,
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
    },
    {
        path: 'participations',
        component: PagesComponent,
        loadChildren: () => import('./participations/participations.module').then(m => m.ParticipationsModule)
    },
    {
        path: 'activities',
        component: PagesComponent,
        loadChildren: () => import('./activities/activities.module').then(m => m.ActivitiesModule)
    },
    {
        path: 'sellers',
        component: PagesComponent,
        loadChildren: () => import('./sellers/sellers.module').then(m => m.SellersModule)
    },
    {
        path: 'products',
        component: PagesComponent,
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule)
    },
    {
        path: 'elements',
        component: ElementsComponent,
        loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule)
    },
    {
        path: '**', // Navigate to Home Page if not found any page
        redirectTo: 'home/fashion',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
