import {Component, OnInit, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CommonFuncsService} from '../../../services/common-funcs.service';
import {ContentService} from '../../../services/content.service';
import {SubscriptionsService} from '../../../services/subscriptions.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-footer-one',
    templateUrl: './footer-one.component.html',
    styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

    @Input() class = 'footer-light'; // Default class
    @Input() themeLogo = 'assets/images/logo-noruegian-embassy.png'; // Default Logo
    @Input() newsletter = true; // Default True

    email = '';

    content$ = new BehaviorSubject(false);
    content: any = {};

    public today: number = Date.now();

    constructor(
        public funcs: CommonFuncsService,
        private contentService: ContentService,
        private service: SubscriptionsService
    ) {
        this.contentService.listSubArea(0, 10, {}, 'footer/all').then((r: any) => {
            this.funcs.setAlphabet(r);
            this.content.reg_newsletter = this.funcs.getText('reg-newsletter');
            this.content.logo_copy = this.funcs.getText('logo-copy');
            this.content.address = this.funcs.getText('about-address');
            this.content.phone = this.funcs.getText('about-phone');
            this.content.mail = this.funcs.getText('about-mail');
            this.content$.next(true);
        });
    }

    ngOnInit(): void {
    }

    strip(txt) {
        return txt.replace(/<[^>]*>?/gm, '');
    }

    subscribe() {
        this.service.insert({email: this.email}).then(r => {
            Swal.fire({
                title: 'Sucesso',
                text: 'O seu email foi registado na nossa newsletter.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#DD6B55',
            });
        });
    }
}
