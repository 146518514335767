import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';

@Injectable({
    providedIn: 'root'
})
export class ContentService extends GenericService {

    constructor(private http1: HttpClient) {
        super(http1);
        this.setModel('content');
    }
}
