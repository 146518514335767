<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of products | slice:0:3 ">
            <ng-template carouselSlide>
                <div>
                    <div class="media" *ngFor="let product of products | slice:0:3 ">
                        <a [routerLink]="[getProductLink(product)]">
                            <img class="img-fluid w-auto"
                                 [defaultImage]="'assets/images/product/placeholder.jpg'"
                                 [lazyLoad]="product.images[0].thumb" alt="">
                        </a>
                        <div class="media-body align-self-center">
                            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                            <a [routerLink]="[getProductLink(product)]">
                                <h6>{{ product.name | titlecase }}</h6>
                            </a>
                            <p>{{ product?.store.name }}</p>
                            <h4>
                                {{ product?.price }} MZN
                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                    <div class="media" *ngFor="let product of products | slice:3:6 ">
                        <a [routerLink]="[getProductLink(product)]">
                            <img class="img-fluid w-auto"
                                 [defaultImage]="'assets/images/product/placeholder.jpg'"
                                 [lazyLoad]="product.images[0].image" alt="">
                        </a>
                        <div class="media-body align-self-center">
                            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
                            <a [routerLink]="[getProductLink(product)]">
                                <h6>{{ product.name | titlecase }}</h6>
                            </a>
                            <p>{{ product?.store.name }}</p>
                            <h4>
                                {{ product?.price }} MZN
                            </h4>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
