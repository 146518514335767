import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  private env = environment;
  public baseUrl = this.env.api;
  public area = '';

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  public setModel(model: string): void {
    this.area = model;
  }

  public getHost(): string {
    return this.baseUrl;
  }

  private getList = async (page: number = 1, pagesize: number = 10, filters: any, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}?v=1`;
    const params: HttpParams = new HttpParams()
        .set('page', String(page))
        .set('pagesize', String(pagesize))
        .set('filters', JSON.stringify(filters));
    return this.http.get(url, {params: params});
  }

  public get_by_id = async (id: string, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}/${id}`;
    return this.http.get(url);
  }

  public get = async (sub: string) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}`;
    return this.http.get(url);
  }

  private post = async (item: any, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}`;
    return this.http.post(url, item);
  }

  private patch = async (id: string, item: any, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}/${id}`;
    return this.http.patch(url, item);
  }

  private delete = async (id: string, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}/${id}`;
    return this.http.delete(url);
  }

  private deleteImage = async (id: string, sub: string | null = null) => {
    const url = `${this.baseUrl}/${this.area}${sub ? '/' + sub : ''}/${id}/image`;
    return this.http.delete(url);
  }

  private handleResult(r: any, resolve: any, reject: any): void {
    if (r) {
      r.subscribe((result: any) => {
        resolve(result);
      }, (e: any) => {
        reject(e);
      });
    } else {
      reject(null);
    }
  }

  public getAvailableLangs(): string[] {
    return environment.languages;
  }

  public getById(id: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.get_by_id(id).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public getByIdSubArea(id: string, sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.get_by_id(id, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public list(page: number, pagesize: number, filters: any): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.getList(page, pagesize, filters).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public listSubArea(page: number, pagesize: number, filters: any, sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.getList(page, pagesize, filters, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public insert(item: any): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.post(item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public insertSubArea(item: any, sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.post(item, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public remove(id: any): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.delete(id).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public removeSubArea(id: string, sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {

      this.delete(id, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public removeImage(id: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.deleteImage(id).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public removeImageSubArea(id: string, sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.deleteImage(id, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setPositionUp(id: string, position: number): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        position
      };
      this.patch(id, item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setStatus(id: string, status: boolean): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        status
      };
      this.patch(id, item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setVisibility(id: string, visibility: boolean): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        visible: visibility
      };
      this.patch(id, item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setStatusSubArea(id: string, sub: string, status: boolean): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        status
      };
      this.patch(id, item, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setHighlight(id: string, highL: boolean): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        highlight: highL
      };
      this.patch(id, item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public setHighlightSubArea(id: string, sub: string, highL: boolean): Promise<any> {
    return new Promise((resolve, reject, ) => {
      const item = {
        highlight: highL
      };
      this.patch(id, item, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public change(id: string, item: any): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.patch(id, item).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public changeSubArea(id: string, sub: string, item: any): Promise<any> {
    return new Promise((resolve, reject, ) => {
      this.patch(id, item, sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }

  public getSubArea(sub: string): Promise<any> {
    return new Promise((resolve, reject, ) => {

      this.get(sub).then(r => {
        this.handleResult(r, resolve, reject);
      });
    });
  }
}
