<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div *ngIf="content$ | async">
								<h4>{{ content.reg_newsletter.title.pt }}</h4>
								<p [innerHTML]="content.reg_newsletter.body.pt"></p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="form-inline subscribe-form">
							<div class="form-group mx-sm-3">
								<input type="email" [(ngModel)]="email" name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Insira o seu email">
							</div>
							<button type="button" class="btn btn-solid" (click)="subscribe()">subscrever</button>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant" *ngIf="content$ | async">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p [innerHTML]="content.logo_copy.body.pt"></p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/compranosso" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
<!--                                <li>-->
<!--                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>-->
<!--                                </li>-->
                                <li>
                                    <a href="https://www.linkedin.com/company/compranosso" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/compranosso/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
<!--                                <li>-->
<!--                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>-->
<!--                                </li>-->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
<!--					<div class="sub-title">-->
<!--						<div class="footer-title">-->
<!--                            <h4>Categorias</h4>-->
<!--                        </div>-->
<!--                        <div class="footer-contant">-->
<!--                            <ul>-->
<!--                                <li><a href="javascript:void(0)">Womens Fashion</a></li>-->
<!--                                <li><a href="javascript:void(0)">Mens Fashion</a></li>-->
<!--                                <li><a href="javascript:void(0)">Kids Fashion</a></li>-->
<!--                                <li><a href="javascript:void(0)">Featured</a></li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--					</div>-->
				</div>
				<div class="col">
<!--					<div class="sub-title">-->
<!--						<div class="footer-title">-->
<!--                            <h4>why we choose</h4>-->
<!--                        </div>-->
<!--                        <div class="footer-contant">-->
<!--                            <ul>-->
<!--                                <li><a href="javascript:void(0)">shipping & return</a></li>-->
<!--                                <li><a href="javascript:void(0)">secure shopping</a></li>-->
<!--                                <li><a href="javascript:void(0)">gallary</a></li>-->
<!--                                <li><a href="javascript:void(0)">affiliates</a></li>-->
<!--                                <li><a href="javascript:void(0)">contacts</a></li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--					</div>-->
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Sobre nós</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list" *ngIf="content$ | async">
						        <li><i class="fa fa-map-marker"></i><span [innerHTML]="content.address.body.pt"></span></li>
						        <li><i class="fa fa-phone"></i>Tel: {{ strip(content.phone.body.pt) }}</li>
						        <li><i class="fa fa-envelope-o"></i>Email: <a href="mailto: {{ strip(content.mail.body.pt) }}">{{ strip(content.mail.body.pt) }}</a></li>
<!--						        <li><i class="fa fa-fax"></i>Fax: 123456</li>-->
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} copyright CompraNosso &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;desenvolvido por <a target="_blank" href="https://veludoementol.co.mz">veludo & mentol</a></p>
                  </div>
                </div>
<!--                <div class="col-xl-6 col-md-6 col-sm-12">-->
<!--                  <div class="payment-card-bottom">-->
<!--                    <ul>-->
<!--                      <li>-->
<!--                        <a><img src="assets/images/icon/visa.png" alt=""></a>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <a><img src="assets/images/icon/paypal.png" alt=""></a>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <a><img src="assets/images/icon/american-express.png" alt=""></a>-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <a><img src="assets/images/icon/discover.png" alt=""></a>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->
