<div class="blog-sidebar">
    <div class="theme-card">
        <h4>Últimas notícias</h4>
        <ul class="recent-blog">
            <li *ngFor="let l of last">
                <div class="media" (click)="goToNews(l)">
                    <img class="img-fluid" [src]="l.thumb" alt="Generic placeholder image">
                    <div class="media-body align-self-center">
                        <h6>{{ l.created_at | amLocale: 'pt' | amDateFormat: 'D MMM YYYY'}}</h6>
                        <p class="text-justify">{{ l.title.pt }}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="theme-card">
        <h4>Próximos eventos</h4>
        <ul class="popular-blog" *ngIf="events$ | async">
            <li *ngFor="let e of events">
                <div class="media" (click)="goToEvent(e)">
                    <div class="blog-date">
                        <span>{{ e.from_date | amLocale: 'pt' | amDateFormat: 'D' }} </span>
                        <span>{{ e.from_date | amLocale: 'pt' | amDateFormat: 'MMM' }}</span>
                    </div>
                    <div class="media-body align-self-center">
                        <h6>{{ e.title.pt }}</h6>
                        <span *ngIf="e.to_date">{{ e.from_date | amLocale: 'pt' | amDateFormat: 'D/M' }} até {{ e.to_date | amLocale: 'pt' | amDateFormat: 'D/M' }}</span>
                    </div>
                </div>
                <div [innerHTML]="e.peek.pt" class="max-height-68"></div>
            </li>
        </ul>
    </div>
</div>
